






























































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

interface Error {
  field: string;
  message: string;
}

@Component
export default class NewPassword extends Vue {
  uid = this.$route.params.uid;

  token = this.$route.params.token;

  newPassword1 = null;

  newPassword2 = null;

  password1Errors: Array<Error> = [];

  password2Errors: Array<Error> = [];

  mainErrors: Array<Error> = [];

  busy = false;

  updatePassword() {
    // Clean past errors first.
    this.busy = true;
    this.password1Errors = [];
    this.password2Errors = [];
    this.mainErrors = [];

    // Attempt to update password.
    this.axios.post(`${process.env.VUE_APP_OKOCHA_URL}rest-auth/password/reset/confirm/`, {
      uid: this.uid,
      token: this.token,
      /* eslint-disable @typescript-eslint/camelcase */
      new_password1: this.newPassword1,
      new_password2: this.newPassword2,
      /* eslint-enable @typescript-eslint/camelcase */
    }).then(() => {
      // Success
      this.busy = false;
      this.$router.push({ name: 'Success', params: { type: 'password' } });
    }).catch((error) => {
      // Failure
      error.response.data.errors.map((rawError: Error) => {
        if (rawError.field === 'new_password1') {
          this.password1Errors.push(rawError);
        } else if (rawError.field === 'new_password2') {
          this.password2Errors.push(rawError);
        } else {
          this.mainErrors.push(rawError);
        }
        return rawError;
      });
      this.busy = false;
    });
  }
}
